.App {
  text-align: center;
  user-select: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.exit_hotspot_menu_button svg, .hotspot_point, .index, .left_arrow img, .right_arrow img {
  filter: drop-shadow(0px 0px 1px black);
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #000000;
}

.empty {
  display: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'GucciSans-Bold';
  src: url('./resources/fonts/GucciSans-Bold.ttf');
  /* IE9 Compat Modes */
}

@font-face {
  font-family: 'GucciSans-Book';
  src: url('./resources/fonts/GucciSans-Book.woff2');
  /* IE9 Compat Modes */
}

.flex {
  display: flex;
}

.gucci_text {
  font-family: 'GucciSans-Book';
}

.pointer {
  cursor: pointer !important;
}

/* DISPLAYS PAGE */
.image-gallery-slide .image-gallery-image {
  object-fit: cover !important;
}

/* .view_button {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 30px;
  height: 30px;
  background-color: rgba(18, 23, 44, 0.8);
  border: 1px solid #c7e1e1;
  cursor: pointer;
} */

.index {
  position: absolute;
  color: #ffffff;
  left: 0px;
  width: 80px;
  height: 30px;
  border-radius: 2px;
  font-family: 'GucciSans-Bold';
  padding-top: 9px;
  position: absolute;
  border-radius: 5px;
  font-size: 1.2em;
}

.left_arrow {
  position: absolute;
  top: 40vh;
  left: 10px;
  height: 50px;
  margin-top: 20px;
  cursor: pointer;
  z-index: 2;
  /*  background: #222534cc;*/
  border-radius: 5px;
  padding-top: 2px;
  padding-right: 3px;
  /*box-shadow: 2px 2px 2px;*/
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.right_arrow {
  position: absolute;
  top: 40vh;
  right: 10px;
  height: 50px;
  margin-top: 20px;
  cursor: pointer;
  z-index: 2;
  border-radius: 5px;
  padding-top: 2px;
  padding-left: 3px;
  /* box-shadow: 2px 2px 2px;*/
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.disabled_right_arrow {
  cursor: auto !important;
  color: grey;
}

.view_icon {
  width: 20px;
  margin-top: 6px;
  cursor: pointer;
}

#filter_button {
  position: absolute;
  top: 20px;
  left: 10px;
  width: 30px;
  height: 29px;
  cursor: pointer;
  padding-top: 1px;
  border-radius: 5px;
}

.filter_icon {
  width: 18px;
  margin-top: 7px;
}

.star_button {
  position: absolute;
  top: 20px;
  right: 10px;
  width: 30px;
  height: 29px;
  cursor: pointer;
  padding-top: 1px;
  border-radius: 5px;
  display: none;
}

.star_icon {
  width: 19px;
  margin-top: 5px;
}

/*thumbnail align left */
.image-gallery-thumbnails .image-gallery-thumbnails-container {
  padding-left: 20px;
  text-align: left !important
}

/*swipe button dimension */
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 60px !important;
  width: 40px !important;
  stroke-width: 0.3px !important;
}

/*thumbnails container */
.image-gallery-thumbnails-wrapper.thumbnails-swipe-horizontal {
  margin-top: -110px !important;

}

.image-gallery-bullets {
  bottom: 120px !important;
}

/*fullscreen gallery */
.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 100vh !important;

}

/*thumbnails dimesion */
.image-gallery-thumbnail-inner {
  max-width: 140px;
  max-height: 86px;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 85px;
}

/*thumbnails dimesion */
.image-gallery-thumbnail {
  width: 140px !important;
  cursor: pointer;
  border: 2px solid transparent !important;

}

/*thumbnails border */
/* .image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus {
  border: 1px solid #c7e1e1 !important;
} */

/*thumbnails hover background */
/* .image-gallery-thumbnail:hover {
  border: 1px solid #c7e1e1 !important;
  background-color: rgb(2, 2, 2);
} */

/*thumbnails hover opacity */
/* .image-gallery-thumbnail:hover .image-gallery-thumbnail-inner {
  opacity: 0.7;
}

/*thumbnails label hidden*/
.image-gallery-thumbnail-label {
  opacity: 0;
  cursor: grab;
}

/*thumbnails label hidden*/
.image-gallery-thumbnail.active .image-gallery-thumbnail-label {
  opacity: 1;
}

/*thumbnails label visible*/
/* .image-gallery-thumbnail:hover .image-gallery-thumbnail-label:hover {
  color: #c7e1e1;
  opacity: 1 !important;
} */

/*map padding*/
.image-gallery-thumbnails {
  padding-left: 94px !important
}

.image-gallery-thumbnail-open {
  padding-left: 190px !important
}

.thumbnails_container_open {
  width: calc(100% - 640px) !important;
  margin-left: 615px !important;
}

.loader_cont {
  position: absolute;
  /* background: #c7e1e1; */
  width: 100%;
  height: 100vh;
  z-index: 5;
}

.loader-line {
  width: 600px;
  height: 3px;
  position: relative;
  overflow: hidden;
  /* background-color: #07504f26; */
  margin: 40px auto;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.loading_text {
  margin-top: calc(50vh - 56px);
  font-family: 'GucciSans-Bold';
  /* color: #07504f; */
  font-size: 50px;
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}

.loader-line._07504f:before {
  background-color: #07504f;
}

.loader-line._000000:before {
  background-color: #000000;
}

.loader-line._8c7725:before {
  background-color: #8c7725;
}

.loader-line._ffffff:before {
  background-color: #ffffff;
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }

  50% {
    left: 20%;
    width: 40%;
  }

  100% {
    left: 100%;
    width: 40%;
  }
}

@keyframes border_animation {
  0% {
    opacity: 0;
    width: 20px;
    height: 20px;
    transform: translate(-10px, -10px);
  }


  40% {
    opacity: 1;
    width: 25px;
    height: 25px;
    transform: translate(-12.5px, -12.5px);
  }

  41% {
    opacity: 0;
    width: 30px;
    height: 30px;
    transform: translate(-15px, -15px);
  }
}

@keyframes border_animation_000000 {
  0% {
    background-color: #ffffff;
    border-color: #000000;
  }

  50% {
    background-color: #000000;
    border-color: #ffffff;
  }
}

@keyframes border_animation_4b000a {
  0% {
    background-color: #ffffff;
    border-color: #4b000a;
  }

  50% {
    background-color: #4b000a;
    border-color: #ffffff;
  }
}

.hotspot_point {
  cursor: pointer;
  position: absolute;
  top: 59.0957%;
  left: 16.4103%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid;
}

.hotspot_point._000000:hover {
  animation: border_animation_000000 1s;
}

.hotspot_point._4b000a:hover {
  animation: border_animation_4b000a 1s;
}

.hotspot_content_point {
  margin: calc(50% - 10px);
  border: none;
  /* color: rgb(0, 0, 0); */
}

@keyframes white_coloring {
  0% {
    color: black
  }

  50% {
    color: white
  }
}

.hotspot_point_border {
  cursor: pointer;
  border-radius: 50%;
  left: calc(50% - 1px);
  position: absolute;
  top: calc(50% - 1px);
  border: none;
  border: 1px solid;
  opacity: 0;
  width: 30px;
  height: 30px;
  transform: translate(-15px, -15px)
}

.hotspot_point_border:hover {
  display: block;
  animation: border_animation 1s;

}

.display_points_cont {
  justify-content: center;
  position: absolute;
  left: 0;
  /* top: 770px;*/
  width: calc(100% - 100px);
  height: 100px;
  display: -webkit-flex;
  padding-left: 50px;
  padding-right: 50px;
  top: calc(50vh + 250px);
}

.selected_display_point {
  width: 20px;
  height: 20px;
  /* background: #07504f; */
  border-radius: 50%;
  cursor: pointer;
  margin-right: 3px;
  margin-left: 3px;
}

.display_point {
  width: 20px;
  height: 20px;
  /* background: #c7e1e199; */
  border-radius: 50%;
  margin-right: 3px;
  margin-left: 3px;
  cursor: pointer;
}

.scroll_cont {
  display: -webkit-flex;
  white-space: nowrap;
  width: 100%;
  z-index: 1;
}

.thumbnails_container {
  margin-left: 150px;
  bottom: 20px;
  height: 95px;
  width: calc(100% - 170px);
  position: absolute;
  bottom: 20px;
  text-align: -webkit-left;
}

.thumbnails_container_no_map {
  margin-left: 10px;
  bottom: 20px;
  height: 95px;
  width: calc(100% - 40px);
  position: absolute;
  bottom: 20px;
  text-align: -webkit-left;
}

.selected-image {
  border-width: 4px;
  border-style: solid;
  margin-left: -3px;
  /* border-color: #c7e1e1; */
}

.sel_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  /* background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
  z-index: 0;
}

.map_thumb {
  width: 140px;
  height: 88px;
  /*background: rgb(255, 255, 255);
  background-color: rgb(255, 255, 255);*/
  /*position: relative;*/
  margin-top: -87.5px;
  margin-left: 10px;
  /* border: 1px solid #f4eecd !important;*/
  background-size: cover;
  background-position-y: bottom;
  background-position-x: center;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 20px;
  cursor: pointer;
}

.map_thumb_open {
  width: 600px;
  height: 375px;
  /* background-color: rgb(255, 255, 255);*/
  /* position: relative;*/
  margin-top: -256.5px;
  margin-left: 10px;
  /* border: 1px solid #f4eecd !important;*/
  background-size: contain;
  position: absolute;
  bottom: 20px;
}

.open_map_button {
  position: absolute;
  bottom: 4px;
  left: 0;
  width: 27px;
  cursor: pointer;
  z-index: 9;
}

.open_map_icon {
  position: absolute;
  bottom: 8px;
  left: 3px;
  width: 10px;
  z-index: 10;
}

.display {
  position: absolute;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  background-size: cover;
  background-repeat: no-repeat;
}


/*FIlTER MENU*/
#filter_menu {
  border-width: 0px;
  position: absolute;
  top: 20px;
  left: 45px;
  margin-left: 10px;
  width: 200px;
  height: 95px;
  background: inherit;
  box-sizing: border-box;
  border-width: 2px;
  border-style: solid;
  /* border-color: #07504f; */
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: "GucciSans-Bold", "Gucci Sans Bold", "Gucci Sans Book", "Gucci Sans", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  /* color: #FFFFFF; */
}

#select_area {
  border-width: 0px;
  width: 185px;
  margin-top: 5px;
  height: 20px;
  background: inherit;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  /* border-color: #c7e1e1; */
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: "GucciSans-Book";
  font-style: normal;
  font-size: 13px;
  /* color: white; */
}

#apply_button {
  border-width: 0px;
  margin-top: 15px;
  width: 185px;
  height: 20px;
  background: inherit;
  /* background-color: #07504f; */
  border-width: 1px;
  border-style: solid;
  /* border-color: #07504f; */
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: "GucciSans-Book";
  font-style: normal;
  font-size: 13px;
  /* color: white; */
  cursor: pointer;
}

#reset_button {
  border-width: 0px;
  margin-top: 5px;
  width: 185px;
  height: 20px;
  /* background-color: #07504f !important; */
  font-family: "GucciSans-Book";
  font-style: normal;
  font-size: 13px;
  border-width: 1px;
  border-style: solid;
  /* border-color: #07504f; */
  background: inherit;
  /* color: white; */
  cursor: pointer;

}

/*HOTSPOTS MENU */
#hotspot_menu {
  border-width: 0px;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  background: inherit;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  z-index: 4;
}

.exit_hotspot_menu_button {
  top: 20px;
  right: 10px;
  border-width: 0px;
  position: absolute;
  width: 40px;
  height: 40px;
  display: flex;
  border-radius: 5px;
  cursor: pointer;
}

.exit_hotspot_menu_icon {
  width: 15px;
  margin-top: 8px;
  margin-left: 8px;
  height: 15px;
  cursor: pointer;
}

/*product card*/
.products_container {
  border-width: 0px;
  position: absolute;
  left: 432px;
  top: calc((100vh - 650px)/2);
  width: calc(100% - 530px);
  height: 600px;
  background: inherit;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  border-radius: 0px;
  display: -webkit-flex;

  padding-right: 50px;
}

.empty_product_container {
  position: absolute;
  top: 25vh;
  height: 148px;
  color: white;
  font-size: 20px;
  font-family: 'GucciSans-Bold';
  text-align: -webkit-center;
  width: 100%;
}


.look_products_container {
  border-width: 0px;
  position: absolute;
  left: 50px !important;
  top: calc((100vh - 650px)/2);
  width: calc(100% - 150px) !important;
  height: 600px;
  background: inherit;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  border-radius: 0px;
  display: -webkit-flex;

  padding-right: 50px;
}

.product_card {
  min-width: 280px;
  height: 440px;
  left: 0px;
  top: 60px;
  visibility: inherit;
  background: white;
  margin-right: 5px;
  cursor: grab;
  position: relative
}

.look_card {
  /* min-width: 380px; */
  height: 535px;
  width: 380px;
  left: 47px;
  top: calc((100vh - 650px)/2);
  visibility: inherit;
  margin-right: 5px;
  cursor: grab;
  position: relative;
  /* background: #0707079c; */
  align-items: center;
  justify-content: center;
  display: flex;
}

.look_name {
  color: white;
  font-family: 'GucciSans-Bold';
  position: absolute;
  top: 565px;
  font-size: 25px;
  width: 380px;
}

.heart {
  position: absolute;
  top: 5px;
  right: 6px;
  cursor: pointer;
}


.product_img {
  border-width: 0px;
  position: absolute;
  left: 0px;
  /* top: 40px; */
  object-fit: scale-down;
  bottom: 100px;
  width: 280px;
  max-height: 315px;
}

.look_img {
  border-width: 0px;
  position: absolute;
  left: auto;
  top: 0px;
  max-width: 370px;
  /* width: 380px; */
  height: -moz-available;
  /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  height: fill-available;
}

.product_name {
  color: #000000;
  text-transform: capitalize;
  font-family: 'GucciSans-Bold';
  top: 350px;
  position: absolute;
  width: 90%;
  margin-left: 5%;
  text-overflow: ellipsis;
  /* white-space: nowrap;*/
  overflow: hidden;

  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  max-height: 36px;
  line-height: 15px;

}

.product_smc {
  color: #000000;
  text-transform: capitalize;
  font-family: 'GucciSans-Book';
  position: absolute;
  width: 100%;
  top: 380px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.see_details_button {
  position: absolute;
  left: 5px;
  top: 405px;
  width: 270px;
  height: 30px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: "GucciSans-Bold";
  font-style: normal;
  font-size: 13px;
  /* color: white; */
  cursor: pointer;
}

.bg_detail_menu {
  position: absolute;
  background: #f6f2e8;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 3;
  display: flex;
  overflow-y: auto;
}

.left_detail_menu {
  width: 50%;
}

.right_detail_menu {
  width: 50%;
}

.detail_product_img {
  border: 1px solid;
  width: 280px;
  height: 280px;
  margin-top: 20px;
  margin-left: calc((100% - 280px)/2);
  margin-right: calc((100% - 280px)/2);
  background-repeat: no-repeat;
  background-size: cover;
}

.errorpage_cont {
  width: 100%;
  height: 100vh;
}

.errorpage_text {
  font-family: 'GucciSans-Bold';
  font-size: 20px;
  position: absolute;
  width: 300px;
  top: 45%;
  left: calc(50% - 150px);
}